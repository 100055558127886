import * as React from 'react'

import SEOIcon from '../icons/seo.svg'
import AccessibilityIcon from '../icons/accessibility.svg'
import EcoConceptionIcon from '../icons/eco-conception.svg'
import UsabilityIcon from '../icons/usability.svg'
import BarometerIcon from '../icons/barometer.svg'

const CriteriaIcon = (slug, className, width = false, height = false) => {
  switch(slug) {
    case 'global':
      return <BarometerIcon viewBox="0 0 24 24" aria-hidden="true" className={className ? className : ''} />
    case 'usability':
      return <UsabilityIcon viewBox="0 0 87 96" aria-hidden="true" className={className ? className : ''} />
    case 'seo':
      return <SEOIcon viewBox="0 0 73 74" aria-hidden="true" className={className ? className : ''} />
    case 'conception':
      return <EcoConceptionIcon viewBox="0 0 79 82" aria-hidden="true" className={className ? className : ''} />
    case 'accessibility':
      return <AccessibilityIcon viewBox="0 0 77 96" aria-hidden="true" className={className ? className : ''} />
    default:
      return 'error'
  }
}
  
export default CriteriaIcon