import brands from '../json/brands.json'
import brands2021 from '../json/2021/brands.json'

const orderBrands = (criteria, sector, year) => {
  let newBrands = year === '2021' ? brands2021 : brands
  let orderedBrands = Object.entries(newBrands)
  let globalAverage = 0
  let sectorAverage = 0

  // calc global average
  const sum = orderedBrands.reduce((a,b) => a + b[1][criteria], 0)
  globalAverage = Math.round(sum / orderedBrands.length)

  if(sector !== 'all') {
    orderedBrands = orderedBrands.filter(brand => brand[1].cat === sector)

    // calc sector average
    const sum = orderedBrands.reduce((a,b) => a + b[1][criteria], 0)
    sectorAverage = Math.round(sum / orderedBrands.length)
  }
  
  orderedBrands = orderedBrands.sort((a,b) => {
    if(b[1][criteria] === a[1][criteria]) {
      return a[0].localeCompare(b[0])
    } else {
      return parseInt(b[1][criteria]) - parseInt(a[1][criteria])
    }
  })

  return {orderedBrands, globalAverage, sectorAverage}
}

export default orderBrands